import React, {useState} from "react";
import mainImage from "../../assets/images/main-image.webp";
import {ContactUsModalComponent} from "../extra/contact.us.modal.component";

export const Home = () => {

    const [isDialogOpen, setIsDialogOpen] =  useState(false);

    const closeDialog = () =>{
        setIsDialogOpen(false);
    }

    const openDialog = () =>{
        setIsDialogOpen(true);
    }

    return (
        <div className='relative' id='top'>

            <div className='brightness-[0.4] sm:h-[260px] md:h-[350px] h-[220px]'>

                    <img src = {mainImage} alt='main' className='object-cover w-full h-[100%]'/>

            </div>

            <div className='absolute top-0 flex justify-center self-center w-full'>

                <div className='flex flex-col text-center text-white w-[80%] max-w-[960px]'>

                    <div className='md:text-5xl sm:text-3xl text-2xl font-light mt-8 md:mt-14'>
                        It's that simple.
                    </div>

                    <div className='md:mt-14 mt-6 text-xs sm:text-base font-light'>
                        We are driven by innovation.
                        Our team is well-versed in the latest technologies.
                    </div>

                    <div className='mt-4 text-xs sm:text-base font-light'>Talk to us about your project.</div>

                    {/*<button className='bg-techvation hover:bg-white hover:-translate-y-0.5 active:translate-y-0.5 hover:shadow-black pr shadow-lg md:w-[150px] hover:text-techvation self-center md:mt-4 md:h-12 rounded-full md:text-base text-sm w-[100px] h-8*/}
                    {/*mt-4*/}
                    {/*' onClick={openDialog}>Get In Touch</button>*/}

                    {/*<ContactUsModalComponent open ={isDialogOpen} closeDialog={closeDialog}/>*/}

                </div>

            </div>

        </div>)

}
