import React from 'react';

export const Footer = () => {

    return(
        <footer className='h-8 bg-techvation text-center flex flex-col text-white text-[7pt]'>
            <div>&copy; 2024</div>
            <a href="https://www.techvationsenterprises.com">www.techvationsenterprises.com</a>

        </footer>
    )

}