import React from "react";
import {NavBar} from "./components/main/NavBar";
import {Footer} from "./components/main/Footer";
import {
    Routes,
    Route,
    Outlet,
    Navigate
} from "react-router-dom";
import {MainComponent} from "./components/main/MainComponent";
import {LearnMoreMainComponent} from "./components/extra/LearnMoreMainComponent";

function App() {
  return (
    <div className='min-w-[400px]'>
        <NavBar/>

        <Outlet/>

        <Routes>
            <Route exact path="/" element = {<MainComponent/>}/>
            <Route exact path="/about-us" element = {<LearnMoreMainComponent/>}/>
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>

        <Footer/>
    </div>
  );
}

export default App;
