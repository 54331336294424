export const LEARN_MORE = [

    {
        title: 'Our Mission',
        text: 'Our mission is to develop innovative, modern and affordable solutions that suit a customer’s needs.'
    },
    {
        title: 'Our Vision',
        text: 'Our vision is to grow into a full-fledged electrical and I.T. services provider. We aim to become a model of rectitude in conducting our business, and also to serve with dignity and provide a customer-friendly environment in our premises.'
    }

]