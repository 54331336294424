import React from "react";

export const AboutUs = () => {

    return (
        <div className=' text-center text-zinc-700 flex justify-center' id='about-us'>

            <div className='w-[80%] max-w-[960px]'>
                <div className='flex flex-col'>
                    <p className='md:text-2xl md:mt-8 text-xl mt-4'>About Us</p>
                    <div className='border-b-2 border-techvation w-[50px] self-center mt-1'></div>

                    <p className='my-8 text-xs sm:text-base '>
                        We have a team of highly skilled individuals you can count on.
                        Built on the pillars of <b>innovation</b>, <b>creativity</b>, and <b>integrity</b>, Tech<span className='text-techvation font-medium'>Vations</span> delivers robust and reliable solutions.
                        We highly value our clients as they are the primary driver of our business.
                    </p>

                </div>

            </div>


        </div>
    )

}