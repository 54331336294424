import React from "react";
import {Home} from "./Home";
import {AboutUs} from "./AboutUs";
import {Services} from "./Services";
import {ContactUs} from "./ContactUs";

export const MainComponent = () => {

    return (
        <div>
            <Home />
            <AboutUs/>
            <Services/>
            <ContactUs/>
        </div>
    )

}