import React from "react";
import {MapPinIcon, BuildingOffice2Icon, PhoneIcon} from "@heroicons/react/24/solid";

export const ContactUs = () => {

    return (

        <div className='flex flex-col text-center items-center' id='contact-us'>
                <p className='md:text-2xl md:mt-8 text-xl mt-4'>Contact Us</p>
                <div className='border-b-2 border-[#FF0010] w-[50px] self-center mt-1'></div>

            <div className='flex flex-col mt-8 text-xs sm:text-sm items-center'>
                <div className='flex'>
                    <BuildingOffice2Icon className='w-4 text-[#b0b0b0] mr-2'/> TechVations Enterprises
                </div>
                <div className='flex'>
                    <MapPinIcon className='w-4 text-[#b0b0b0] mr-2'/> <span>Bulawayo, Zimbabwe</span>
                </div>

                <div className='flex'>
                    <PhoneIcon className='w-4 mr-2 text-[#b0b0b0]'/>+263 78 315 0702
                </div>
            </div>

            <div className='my-4 flex'>
                <a className="mr-4" href="https://api.whatsapp.com/send?phone=263783150702&text=Hello%2C%20I'm%20would%20to%20enquire%20about" title="Image from freeiconspng.com"><img src="https://www.freeiconspng.com/uploads/logo-whatsapp-png-pic-0.png" width="30" alt="whatsapp_logo" /></a>
                <a href="mailto:fourtensoftwares@gmail.com" title="Image from freeiconspng.com"><img src="https://www.freeiconspng.com/uploads/gmail-logo-icon-4.png" width="30" alt="gmail_icon" /></a>
            </div>

        </div>
    )

}