import logo from "../../assets/images/company_logo.png";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export const NavBar = () => {

    const navigate = useNavigate();
    const [isMenuOpen, openMenu] = useState(false);
    
    const toggleMenu = () => {
        openMenu(prevState => !prevState);
    }

    const closeMenu = () =>{
        openMenu(false);
    }

    return (
        <div className='h-10 drop-shadow-lg bg-zinc-100 sm:h-12 flex flex-col justify-center md:flex-row items-center px-[24px] top-0 sticky z-50'>

            <div className='flex w-full'>

                <div className='min-w-[80px] flex'>
                    <a href="https://www.fourtensoftwares.com">
                        <img src={logo} alt='fourten_logo' className='w-[80px]'></img>
                    </a>
                </div>
                
                <div className='ml-auto flex items-center md:hidden'>
                
                <button className='w-4' onClick={(event) => {
                    event.preventDefault();
                    toggleMenu();
                }}>
                        {isMenuOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                </button>

                </div>
            </div>

            <div className={`${isMenuOpen ? 'flex': 'hidden'} md:flex ml-auto h-[100px] md:h-full  bg-zinc-100 w-full md:flex-row flex-col md:w-[180px] justify-between absolute md:relative top-10 md:top-0 transition ease-in-out duration-200`}>

            <button className= 'p-0.5 h-full md:w-[80px] text-xs w-full md:mr-2 active:translate-y-0.5 text-zinc-700 border-b-2 active:border-techvation hover:border-b-2 md:hover:border-b-4 hover:border-[#BCBCBC]'
                        onClick={(event) => {
                          event.preventDefault();
                          navigate('/');
                          closeMenu();
                        }}
                >
                    Home
                </button>

                <button className= 'text-xs p-0.5 h-full md:w-[80px] w-full active:translate-y-0.5 text-zinc-700 border-b-2 active:border-techvation hover:border-b-2 md:hover:border-b-4 hover:border-[#BCBCBC]'
                        onClick={(event) => {
                          event.preventDefault();
                          navigate('/about-us');
                          closeMenu();
                        }}
                >
                    About Us
                </button>

            </div>

        </div>
    )

}