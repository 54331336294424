import {
    GlobeAltIcon,
    DevicePhoneMobileIcon,
    ComputerDesktopIcon,
    CodeBracketIcon,
    Cog8ToothIcon,
    UserIcon
} from "@heroicons/react/24/solid";
import React from "react";
import {BoltIcon, SunIcon} from "@heroicons/react/20/solid";

export const SERVICES = [

    {
        icon: <BoltIcon className='sm:w-8 md:w-12 w-6 text-white'/>,
        title: 'Electrical'
    },
    {
        icon: <SunIcon className='sm:w-8 md:w-12 w-6 text-white'/>,
        title: 'Solar'
    },
    {
        icon: <ComputerDesktopIcon className='sm:w-8 md:w-12 w-6 text-white'/>,
        title: 'Industrial Automation'
    },
    {
        icon: <CodeBracketIcon className='sm:w-8 md:w-12 w-6 text-white'/>,
        title: 'IOT Solutions'
    },
    {
        icon: <UserIcon className='sm:w-8 md:w-12 w-6 text-white'/>,
        title: 'Training'
    }

]