import React from "react";
import learnMoreImage from "../../assets/images/learn-more-image.webp";

export const LearnMore = () => {

    return (
        <div className='relative'>

            <div className='brightness-[0.4] sm:h-[260px] md:h-[350px] h-[220px] flex'>
                <img src = {learnMoreImage} alt='learn-more' className='object-cover w-full h-[100%]'/>
            </div>

            <div className='absolute top-0 flex justify-center self-center w-full'>

                <div className='flex flex-col text-center text-white w-[80%] max-w-[960px]'>

                    <div className='md:text-5xl sm:text-3xl text-2xl font-light mt-8 md:mt-14'>
                        About Us
                    </div>

                    <div className='border-b-2 border-techvation w-[50px] self-center mt-1'></div>

                    <div className='md:mt-14 mt-6 text-xs sm:text-base font-light'>
                        We have a team of highly skilled individuals you can count on.
                        Built on the pillars of <b>innovation</b>, <b>creativity</b>, and <b>integrity</b>, Tech<span className='text-techvation font-medium'>Vations</span> delivers robust and scalable solutions.
                        We highly value our clients as they are the primary driver of our business.
                    </div>
                    

                </div>


            </div>


        </div>
    )

}