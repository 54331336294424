import React from "react";
import {LearnMore} from "./LearnMore";
import {MoreAboutUs} from "./MoreAboutUs";

export const LearnMoreMainComponent = ()=> {

    return(
        <div>
            <LearnMore/>
            <MoreAboutUs/>
        </div>
    )

}