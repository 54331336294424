import React from "react";

export const DataComponent = (props) => {

    const {title, text} = props;

    return(
        <div className='flex flex-col mb-12'>
            <p className='md:text-2xl md:mt-8 text-xl mt-4'>{title}</p>
            <div className='border-b-2 border-techvation w-[50px] self-center mt-1'></div>

            <p className='mt-8 text-xs sm:text-base'>
                {text}
            </p>

        </div>
    )

}