import React from "react";
import {SERVICES} from "../other/service.constants";
import {Service} from "../other/Service";
import {Tech} from "../other/Tech";
import {TECHS} from "../other/tech.constants";


export const Services = () => {

    return (

        <div className='bg-zinc-100 text-center text-zinc-700 flex justify-center py-4' id='services'>
            <div className='w-[80%] max-w-[60rem]'>
                <div className='flex flex-col'>
                    <p className='md:text-2xl md:mt-4 text-xl mt-0'>Services</p>
                    <div className='border-b-2 border-techvation w-[50px] self-center mt-1'></div>

                    <p className='mt-8 text-xs sm:text-base'>
                        We offer the following services.
                    </p>

                    <div className='mt-12 flex justify-between'>

                        {
                            SERVICES.map(service => <Service icon={service.icon} key={service.title} title={service.title}/>)
                        }

                    </div>

                    {/*<div className= 'my-8 flex justify-evenly w-[21rem] md:w-[36rem] lg:w-[50rem] self-center flex-wrap'>*/}

                    {/*    {*/}
                    {/*        TECHS.map(tech => <Tech name={tech.name} key={tech.name} image={tech.image} title={tech.title}/>)*/}
                    {/*    }*/}

                    {/*</div>*/}

                </div>

            </div>
        </div>
    )

}