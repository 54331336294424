import React from 'react';

export const Service = (props) => {

    const {icon, title} = props;

    return (

            <div className='flex flex-col sm:w-16 md:w-20 w-10'>
                <div className='rounded-full sm:h-16 md:h-20 h-10 bg-techvation flex justify-center'>
                    {icon}
                </div>

                <div className='mt-2 sm:text-base text-xs'>{title}</div>
            </div>
    )

}