import React from "react";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {CORE_VALUES} from "./values.constants";

export const CoreValuesComponent = () => {


    return (
        <div className='flex flex-col mb-12'>
            <p className='md:text-2xl md:mt-8 text-xl mt-4'>Our Values</p>
            <div className='border-b-2 border-techvation w-[50px] self-center mt-1'></div>

            <ul className='mt-8 text-xs sm:text-base flex flex-col items-center'>

                {CORE_VALUES.map(value => <li key={value} className='flex items-center mb-2'><CheckCircleIcon className="w-3 sm:w-4 text-techvation mr-1"/><span>{value}</span></li>)}

            </ul>

        </div>
    )

}