import React from "react";
import {LEARN_MORE} from "./about.us.constants";
import {DataComponent} from "./Data.Component";
import {CoreValuesComponent} from "./core.values.component";

export const MoreAboutUs = () => {

    return(
        <div className='bg-zinc-100 text-center text-zinc-700 flex justify-center' id='services'>
            <div className='w-[80%] max-w-[960px]'>

                {LEARN_MORE.map(data =>

                    <DataComponent title = {data.title} text = {data.text}/>

                )}

                <CoreValuesComponent/>

            </div>
        </div>
    )

}